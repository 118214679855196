<template>
  <div class="bg"></div>
  <div class="bg bg2"></div>
  <div class="bg bg3"></div>
  <div class="col-12 col-sm-12 col-md-8 mx-auto py-md-5 content">
    <main>
      <h4 class="text-center my-5 main-heading">$DUCK STAKING
</h4>
      <div class="main-info-one">
            <!-- <div class="main-info">
              <b>T.V.L</b> (updated every 10m)
              <span class="main-info-val">{{ (3152.44).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) }} USD</span>
            </div> -->

        
            <div class="main-info" v-if="stakedBalanceInEther">
              <b>Locked</b>
              <span class="main-info-val" v-if="stakedBalanceInEther">{{ stakedBalanceInEther.toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 }) }} DUCK</span>

            </div>
          </div>


      <div class="col-12 col-md-12 mx-auto card mb-5 p-2 section-one">
          <div class="text-center mx-auto" style="margin-top: -50px">
            <img
              style="width: 200px;height:200px;"
              class="m-5"
              src="https://duckavax.com/pepe2.png"
            />
          </div>
        <template v-if="!wallet.account">
          <div class="wallet-btn-div">
            <button class="btn wallet-btn" @click="connect">
              <img
                class="metamask-img"
                src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg"
                alt="Metamask"
              />
              Connect Metamask
            </button>
          </div>
        </template>
        <hr />
        <div>
          <div class="main-info-two main-info">
            <b>A.P.R.</b>
            <span class="main-info-val">1,034%</span>
          </div>
          
          <div class="main-info-one">
            <template v-if="wallet.account">

            <div class="main-info">
              <b>Stake</b>
              <span class="main-info-val" v-if="stakedDuck > 0">{{ parseFloat(stakedDuck).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} $DUCK</span>
            </div>

            <div class="main-info">
              <b>Rewards</b>
              <span class="main-info-val" v-if="rewards > 0">{{ parseFloat(rewards).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} $DUCK</span>
              <span class="main-info-val" v-else>0 $DUCK</span>
            </div>
            </template>
          </div>
          <div v-if="message[0] == 1" class="alert alert-primary" role="alert">
            {{ message[1] }}
          </div>
          <div v-if="message[0] == 0" class="alert alert-danger" role="alert">
            {{ message[1] }}
          </div>

          

          <template v-if="wallet.account">

            <template v-if="this.stakedDuck > 1">
              <button
                v-if="!loadingUnstake"
                type="button"
                @click="unstake"
                class="btn btn-danger btn-lg my-3"
              >
                Unstake All
              </button>
              <button
                v-else
                type="button"
                class="btn btn-outline-danger btn-lg my-3"
                disabled="true"
              >
                Unstaking...
              </button>
            </template>
            <input
              type="number"
              class="form-control"
              v-model="stakeAmount"
              placeholder="Amount to Stake (these are the DUCKLINGS you will LOCK for X days)"
            />
          <!-- Day Select Dropdown -->
            <select v-model="selectedDay" placeholder="Select Days" class="form-select my-3">
              <option value="0" disabled selected>
                Click to Lock Time in Days
              </option>
              <option v-for="day in 30" :key="day" :value="day">
                {{ day }} day lock - Yield: {{ calculateApr(day) }}%
              </option>            
            </select>
            <div class="d-grid">
              <button
                v-if="!loading"
                type="button"
                @click="stake"
                class="btn btn-danger btn-lg my-3"
              >
                Stake
              </button>
              <button
                v-else
                type="button"
                class="btn btn-outline-danger btn-lg my-3"
                disabled="true"
              >
                Staking...
              </button>
              <small
                >{{ wallet.account.substr(0, 6) }}...{{
                  wallet.account.substr(
                    wallet.account.length - 3,
                    wallet.account.length
                  )
                }}</small
              >
            </div>

          </template>
            <h3 >How it works?</h3>
            <hr>

            <h4 style="margin-top: 50px; !important">Staking</h4>
            <hr>
            <p>You will need to sign 2 Transactions: one to approve spending of your $DUCK by the Smart contract, one to stake the $DUCK</p>
            <p>Users can deposit $DUCK and choose a staking duration (1 day to 1 month).
            </p>
            <h4 style="margin-top: 50px; !important">Claiming Rewards</h4>
            <hr>

            <p>Rewards generated by staking can be claimed at any time, with accumulation every second.
            </p>
            <h4 style="margin-top: 50px !important;">Withdrawal</h4>
            <hr>

            <p>Users can withdraw rewards at any time with a 3% withdrawal fee.
                Withdrawn coins can be sold or reinvested.
            </p>
            <h4 style="margin-top: 50px; !important">Interrupting Staking</h4>
            <hr>

            <p>If staking is interrupted (withdrawal before duration completion), a 10% tax is applied.
                Completing the staking duration incurs 0% fees.
            </p>
            <h4 style="margin-top: 50px; !important">Fee Destination</h4>
            <hr>

               <p>All collected fees (withdrawal and interruption) are sent to the Burn Wallet.
                The Burn Wallet fuels the staking pool and contributes to overall supply burn.
          </p>
        </div>
      </div>

      <div class="">
        <ul class="col-12 col-md-12 mx-auto list-group">
          <li
            class="list-group-item d-flex justify-content-left align-items-center player-list"
            v-for="i in smartEvents" :key="i"
          >
          <img
                style="width: 70px; height: 70px;margin-right: 20px;border-radius: 5px;"
                :src="
                  'https://api.dicebear.com/7.x/identicon/svg?seed=Casper' +
                  Math.random()
                "
              />
            <div>
          
              <p style="font-size: 20px;">
                {{ i.address.substr(0, 6) }}...{{
                  i.address.substr(
                    i.address.length - 3,
                    i.address.length
                  )}} {{ i.event }} <b style="color: seagreen">{{ parseFloat(i.amount).toFixed(2) ?? 'n/a' }}</b> DUCK.</p>
            </div>
          </li>
        </ul>
      </div>
    </main>
    <footer class="text-center pt-3 footer">
      &copy; 2023 - <a href="duckavax.com" target="_blank">duckavax.com</a>
    </footer>
  </div>
</template>
<script>
import Web3 from "web3";
import BigNumber from "bignumber.js"

export default {
  data() {
    return {
      smartEvents: [],
      web3: null,
      stakedDuck: 0,
      stakedBalanceInEther: 0,
      selectedDay: 0,
      stakeAmount: null,
      loading: false,
      loadingUnstake: false,
      message: [],
      rewards: 0,
      wallet: {
        account: null,
        contract: null,
      },
    };
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', () => {

      // Event listener for the whole document
      document.addEventListener('click', () => {
        var audio = new Audio("duck.mp3"); 
        audio.volume = 0.10;
        audio.play();
      });
    })
  },

  methods: {
    calculateApr(day) {
      const initialApr = 0.1;
      const finalApr = 90;
      const numDays = 30;
      const aprIncreasePerDay = finalApr / (numDays - 1);
      return (initialApr + aprIncreasePerDay * (day - 1)).toFixed(2);
    },
    etherToWei(etherAmount) {
        const etherStr = etherAmount.toString();
        const parts = etherStr.split('.');
        let weiStr = parts[0];

        if (parts.length > 1) {
            let decimalPart = parts[1];
            if (decimalPart.length > 18) {
                decimalPart = decimalPart.substring(0, 18); // Truncate to 18 decimal places
            }
            weiStr += decimalPart.padEnd(18, '0');
        } else {
            weiStr += '0'.repeat(18);
        }

        return weiStr;
    },
    async fetchStakedTokens() {
    try {
        // ERC20 Token Contract Address
        const tokenAddress = '0x5B96fCb5d57290882476062305f6606835631DD0';

        // Staking Contract Address
      const stakingContractAddress = '0x8eA941C3506BBd509BC30f7C0E4E54962777b972';

        // ERC20 token contract ABI (only the 'balanceOf' function)
        const erc20Abi = [
            {
                "constant": true,
                "inputs": [{"name": "_owner", "type": "address"}],
                "name": "balanceOf",
                "outputs": [{"name": "balance", "type": "uint256"}],
                "type": "function"
            },
        ];

        // Creating instance of the ERC20 token contract
        const tokenContract = new this.web3.eth.Contract(erc20Abi, tokenAddress);

        // Fetching the balance
        const stakedBalance = await tokenContract.methods.balanceOf(stakingContractAddress).call();

        // Assuming the balance is returned in Wei, convert it to Ether
        const stakedBalanceInEther = this.web3.utils.fromWei(stakedBalance, 'ether');
        this.stakedBalanceInEther = stakedBalanceInEther * 1
        
    } catch (error) {
        console.error("Error fetching staked ERC20 tokens: ", error);
    }
},

    async stake() {
      let vue = this;

      if (!this.stakeAmount) {
        vue.message = [0, `You need to deposit more than 0 $DUCK`];
        console.log(`You need to deposit more than 0 $DUCK`)
        return;
      }
      if (this.selectedDay < 1) {
        vue.message = [0, `You must select a lock period in days`];
        console.log(`You must select a lock period in days`)
        return;
      }
      this.loading = true;
      try {
        const amountInWei = this.etherToWei(this.stakeAmount);

        // Allow spending of coins
        this.approve(amountInWei)

        const secondsInADay = 86400;
        const timeInSeconds = this.selectedDay * secondsInADay;

        // Sending a transaction to the smart contract
        await this.wallet.contract.methods.stakeTokens(amountInWei * 1, timeInSeconds * 1)
        .send({ from: this.wallet.account, gas: 300000 })
        .on('transactionHash', function(hash) {
          vue.message = [1, `Transaction hash: ${hash}`]
          console.log(`Transaction hash: ${hash}`)
        })
        .on('receipt', function (receipt) {
          vue.message = [1, `Stacked successfully: ${receipt.transactionHash}`];
          console.log(`Stacked successfully: ${receipt.transactionHash}`);
        })
        .on('error', function (error) {
          vue.message = [0, `Error in staking #2: ${error}`];
          console.error(`Error in staking #1: ${error}`);
        });
      } catch (error) {
        vue.message = [0, `Error in staking #2: ${error}`];
        console.error(`Error in staking #2: ${error}`);
      }
      this.loading = false

    },
    async approve(amountInWei) {
      let vue = this;

      try {
        const tokenAddress = '0x5b96fcb5d57290882476062305f6606835631dd0'; // ERC20 Token Address
        const contractAddress = this.wallet.contract._address; // Your contract's address

        // ERC20 token contract ABI (only the 'approve' function)
        const erc20Abi = [
          {
            "constant": false,
            "inputs": [
              {
                "name": "_spender",
                "type": "address"
              },
              {
                "name": "_value",
                "type": "uint256"
              }
            ],
            "name": "approve",
            "outputs": [
              {
                "name": "",
                "type": "bool"
              }
            ],
            "type": "function"
          }
        ];

        // Creating instance of the ERC20 token contract
        const tokenContract = new this.web3.eth.Contract(erc20Abi, tokenAddress);

        // Approving the smart contract to spend tokens
        await tokenContract.methods.approve(contractAddress, amountInWei)
          .send({ from: this.wallet.account, gas: 300000 })
          .on('transactionHash', function (hash) {
            vue.message = [1, `Approval transaction: ${hash}`];
            console.log(`Approval transaction: ${hash}`);
          })
          .on('receipt', function (receipt) {
            vue.message = [1, `Approval granted: ${receipt.transactionHash}`];
            console.log(`Approval granted: ${receipt.transactionHash}`);
          })
          .on('error', function (error) {
            vue.message = [0, `Approval Error #1: ${error}`];
            console.log(`Approval Error #1: ${error}`);
          });

      } catch (error) {
        vue.message = [0, `Approval Error #2: ${error}`];
        console.log(`Approval Error #2: ${error}`);
      }
    },
    async unstake() {
      this.loadingUnstake = true;
      let vue = this
      try {
        const amountInWei = this.etherToWei(this.stakedDuck);

        await this.wallet.contract.methods.unstakeTokens(amountInWei * 1)
          .send({ from: this.wallet.account, gas: 300000 })
          .on('transactionHash', function(hash) {
            console.log(`Transaction hash: ${hash}`);
            vue.message = [1, `Transaction hash: ${hash}`];
          })
          .on('receipt', function (receipt) {
            console.log(`UNSTAKE OK: ${receipt.transactionHash}`);
            vue.message = [1, `Succesfully Removed Staked Tokens: ${receipt.transactionHash}`];
          })
          .on('error', function (error) {
            console.error(`ERROR UNSTAKE #1: ${error}`);
            vue.message = [0, `Error while unstaking #1: ${error}`];
          });
      } catch (error) {
        console.error(`ERROR UNSTAKE #2: ${error}`);
        vue.message = [0, `Error while unstaking #2: ${error}`];
      }
      this.loadingUnstake = false;
    },
    
    async fetchRecentEvents(eventType) {
      let vue = this
        try {
            if (!this.wallet || !this.wallet.contract) {
                console.error("Contract not connected");
                return;
            }

            // Get the latest block number
            const latestBlock = await this.web3.eth.getBlockNumber();

            // Use BigNumber for arithmetic operations
            const fromBlock = new BigNumber(latestBlock).minus(2000);

            // Fetch past events within the last 500 blocks
            let events = await this.wallet.contract.getPastEvents(eventType, {
                fromBlock: fromBlock.toFixed(), // Convert BigNumber to string
                toBlock: 'latest'
            });
            console.log(events)
          events.forEach(event => {
              let _amount = event.returnValues.amount.toString().replace('n', '');
            let log = {
                  address: event.returnValues.user,
                  event: event.event,
                  amount: vue.web3.utils.fromWei(_amount, 'ether')
              };
              vue.smartEvents.push(log);
              console.log(log)
            });
        } catch (error) {
            console.error(`Error fetching ${eventType} events: `, error);
        }
    },

    async displayRecentEvents() {
        await this.fetchRecentEvents('Staked', 15);
        await this.fetchRecentEvents('Unstaked');
        await this.fetchRecentEvents('RewardPaid', 5);
    },

    async fetchStakedBalance() {
        if (!this.web3 || !this.wallet.contract) {
            // printResult("Please connect to MetaMask first.");
            return;
        }

        try {
            const stakedBalance = await this.wallet.contract.methods.stakingBalance(this.wallet.account).call();
            // Assuming the balance is returned in Wei, convert it to Ether
            const stakedBalanceInEther = this.web3.utils.fromWei(stakedBalance, 'ether');
            this.stakedDuck = stakedBalanceInEther;
            
        } catch (error) {
            console.error("Error fetching staked balance: ", error);
        }
    },

    async fetchRewards() {
      if (!this.web3 || !this.wallet.contract) {
        console.error("Web3 not initialized or contract not connected");
        return;
      }

      try {
        const rewards = await this.wallet.contract.methods.getReward(this.wallet.account).call();
        const rewardsInEther = this.web3.utils.fromWei(rewards, 'ether');
        this.rewards = rewardsInEther;

      } catch (error) {
        console.error("Error fetching rewards: ", error);
      }
    },

    async connect() {
      let vue = this;
      console.log('called connect')
      if (typeof ethereum === "undefined") {
        let error = `Metamask not connected. Make sure you have the Metamask plugin, and you are logged in to your MetaMask account.`
        console.error(error);
        vue.message = [0, error];
        return;
      }

      // Create a Web3 instance
      this.web3 = new Web3(window.ethereum);
      await this.getAccount();
      await this.connectContract();
      await this.fetchRewards();
      await this.fetchStakedBalance();
      await this.fetchStakedTokens();
      await this.displayRecentEvents();
    },

    async getAccount() {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      this.wallet.account = accounts[0];
    },

    async connectContract() {
      const response = await fetch("./DuckStaking.json");
      const data = await response.json();

      this.wallet.contract = new this.web3.eth.Contract(
        data.abi,
        "0x8eA941C3506BBd509BC30f7C0E4E54962777b972"
      );
    },
  },
};
</script>
<style>
body {
  /* background: rgb(233, 46, 61);
  background: linear-gradient(
    -180deg,
    rgba(233, 46, 61, 1) 0%,
    rgba(78, 0, 6, 1) 100%
  ); */
  font-family: "Press Start 2P", monospace;
}

h1,
h2,
h3,
h4,
h5,
footer {
  color: black;
  padding: 0 0;
  margin-bottom: 10px;
  margin-top: 10px;
}
.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(
    -60deg,
    rgb(62, 142, 64) 50%,
    rgb(19, 60, 44) 50%
  );
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg3 {
  animation-duration: 5s;
}

/* .spin {
      animation: spin 0.5s infinite;
  } */
.shake {
  animation: shake 0.4s infinite;
}
@keyframes shake {
  0% {
    transform: rotate(25deg);
  }
  50% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(25deg);
  }
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

.main-heading {
  color:white;
  font-size: 55px;
}

.wallet-btn-div {
  text-align: center;
}

.wallet-btn {
  width: 450px;
  height: 100px;

  background-color: #008b66;
  color: #04e7ab;
}

.wallet-btn:hover {
  background-color: #04e7ab;
  color: #008b66;
}

.metamask-img {
  height: 25px;
  width: 25px;
}

.section-one,
.section-two {
  padding: 5% !important;
}

.player-list {
  font-size: 8px;
}

.main-info-one {
  display: flex;
  margin-bottom: 5%;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 3%;
}

.main-info-two {
  gap: 20px;
  margin-bottom: 5%;
}

.main-info {
  background-color:     rgb(62, 142, 64);
  color: aliceblue;
  flex: 1 1 0px;
  padding: 4%;
  border-radius: 15px;
}

.main-info-val {
  float: right;
  color: white;
}
@media only screen and (max-width: 600px) {
  .main-info-one,
  .main-info-two {
    display: block;
    margin: auto;
  }
  .main-info {
    margin-bottom: 3%;
  }
}

.footer {
  font-size: 10px;
}
</style>
